<template>
    <frontoffice-layout :title="$t('booking', { id: booking.token })">
        <template #title-meta> 
            <v-tag v-if="booking.status" :variant="booking.status.variant">
                {{ $t(booking.status.name) }}
            </v-tag>
        </template>

        <section class="shell">
            <article>
                <ul class="grid grid-cols-3 gap-6 <md:grid-cols-1">
                    <li>
                        <v-card class="flex items-center p-2">
                            <v-icon class="bg-orange-100 text-orange-400 rounded-xl flex items-center justify-center w-10 h-10" name="schedule" />

                            <div class="ml-4">
                                <v-heading level="h6"> {{ $t('duration') }} </v-heading>

                                <span class="font-semibold text-md">
                                    {{ booking.duration ? $dayjs().startOf('day').add(booking.duration, 'second').format('H[h]mm[min]') : '--' }}
                                </span>
                            </div>
                        </v-card>
                    </li>

                    <li>
                        <v-card class="flex items-center p-2">
                            <v-icon class="bg-blue-100 text-blue-400 rounded-xl flex items-center justify-center w-10 h-10" name="map" />

                            <div class="ml-4">
                                <v-heading level="h6"> {{ $t('distance') }} </v-heading>

                                <span class="font-semibold text-md">
                                    {{ booking.distance ? `${(Math.ceil(booking.distance / 1000))}km` : '--' }}
                                </span>
                            </div>
                        </v-card>
                    </li>

                    <li>
                        <v-card class="flex items-center p-2">
                            <v-icon class="bg-purple-100 text-purple-400 rounded-xl flex items-center justify-center w-10 h-10" name="euro" />

                            <div class="ml-4">
                                <v-heading level="h6"> {{ $t('price') }} </v-heading>

                                <span class="font-semibold text-md"> {{ $fallback(booking.price, { type: 'price' }) }} </span>
                            </div>
                        </v-card>
                    </li>
                </ul>
            </article>

            <hr class="my-4">

            <v-card :title="$tc('appointment')">
                <ul class="grid auto-cols-auto gap-4">
                    <li class="mb-3 col-span-4 <md:col-span-12">
                        <v-heading level="h6" class="mb-1"> {{ $t('label.date_time') }} </v-heading>
                        {{ $fallback(booking.schedule_departure, { type: 'date' }) }}
                    </li>

                    <li class="mb-3 col-span-4 <md:col-span-12">
                        <v-heading level="h6" class="mb-1"> {{ $t('label.pickup_address') }} </v-heading>
                        {{ $fallback(booking.pickup_address) }}
                    </li>

                    <li class="mb-3 col-span-4 <md:col-span-12">
                        <v-heading level="h6" class="mb-1"> {{ $t('label.drop_off_address') }} </v-heading>
                        {{ $fallback(booking.dropoff_address) }}
                    </li>

                    <li class="mb-3 col-span-4 <md:col-span-12">
                        <v-heading level="h6" class="mb-1"> {{ $tc('label.waypoint', 2) }} </v-heading>

                        <ul v-if="booking.itinerary?.length > 2" class="list-disc pl-4">
                            <li v-for="(waypoint, idx) in booking.itinerary.slice(1, booking.itinerary?.length - 1)" :key="idx">
                                {{ waypoint }}
                            </li>
                        </ul>

                        <span v-else> -- </span>
                    </li>

                    <li class="mb-3 col-span-4 <md:col-span-12">
                        <v-heading level="h6" class="mb-1"> {{ $t('label.nb_seat') }} </v-heading>
                        {{ $fallback($tc('nb_seat', { count: booking.nb_seats })) }}
                    </li>

                    <li class="mb-3 col-span-4 <md:col-span-12">
                        <v-heading level="h6" class="mb-1"> {{ $t('label.flight_number') }} </v-heading>
                        {{ $fallback(booking.flight_number) }}
                    </li>

                    <li class="mb-3 col-span-12">
                        <v-heading level="h6" class="mb-1"> {{ $t('label.comments') }} </v-heading>
                        {{ $fallback(booking.comments) }}
                    </li>
                </ul>

                <template v-if="booking?.status?.id === 1 || booking?.status?.id === 2">
                    <footer class="flex justify-end">
                        <v-button v-confirm="$t('confirm.message', { action: $t('action.delete'), item: $t('booking') })" variant="danger" @confirm="handleUpdate(3)"> 
                            {{ $t('action.delete') }} 
                        </v-button>
                    </footer>
                </template>
            </v-card>
        </section>
    </frontoffice-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters('member_bookings', [
            'booking',
        ]),
    },

    mounted () {
        this.getBooking(this.$route.params.id);
    },

    methods: {
        ...mapActions('member_bookings', [
            'getBooking',
            'updateBooking',
        ]),

        handleUpdate (status_id) {
            this.booking.status_id = status_id;

            this.updateBooking(this.booking)
        }
    }
}
</script>
